/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
// EnvironmentContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import useFetch from 'use-http';
import config from '../../config';

interface Environment {
  SK: string;
  name: string;
  accountId: string;
  region: string;
  resources?: any[];
  storage?: any;
  network?: any;
  compute?: any;
  [key: string]: any;
}

interface EnvironmentContextProps {
  environments: Environment[];
  setEnvironments: React.Dispatch<React.SetStateAction<Environment[]>>;
  availableRegions: any[];
  userCF: any;
  loading: boolean;
  account: any;
  selectedWorkspace: any;
  createEnvironment: (environmentData: any) => Promise<void>;
  deleteEnvironment: (environment: Environment) => Promise<void>;
  getUserCF: () => Promise<void>;
  getRegions: () => Promise<void>;
  deployStack: (environment: Environment, stack: string) => Promise<void>;
  deleteStack: (environment: Environment, stack: string) => Promise<void>;
  getStackResources: (environment: Environment) => Promise<void>;
  handleEnvironmentNameChange: (event: any, environment: Environment) => void;
  saveEnvironmentName: (environment: Environment) => Promise<void>;
  notify: (message: string) => void;
}

const EnvironmentContext = createContext<EnvironmentContextProps | undefined>(undefined);

export const EnvironmentProvider: React.FC<any> = ({ user, account: initialAccount, selectedWorkspace: initialWorkspace, notify, children }) => {
  const { post, get, response, loading } = useFetch(config.url);

  const filterOutReservedEnvironments = (environments: Environment[]) => {
    return environments.filter((environment: Environment) => environment.name !== '🚀 Production' && environment.name !== '🧪 Development');
  };

  const [environments, setEnvironments] = useState<Environment[]>(filterOutReservedEnvironments(initialWorkspace.environments) || []);
  const [availableRegions, setAvailableRegions] = useState<any[]>([]);
  const [userCF, setUserCF] = useState<any>('');
  const [account, setAccount] = useState(initialAccount);
  const [selectedWorkspace, setSelectedWorkspace] = useState(initialWorkspace);

  const getUserCF = async () => {
    const userCFRequest = await get(`environment/setup-user/`);
    if (response.ok) {
      setUserCF(userCFRequest.json);
    }
  };
  const getRegions = async () => {
    const regions = await get(`environment/get-regions/`);
    if (response.ok) {
      setAvailableRegions(regions.regions.Regions);
    }
  };
  const getStackResources = async (environment: Environment) => {
    const environmentRequest = await post(`environment/get/${user.sub}`, environment);
    if (response.ok && environmentRequest) {
      return environmentRequest.environment;
    }
    return environment;
  };
  const hydrateAllEnvironments = async (newEnvironments?: Environment[]) => {
    const environmentsToHydrate = newEnvironments || environments;
    const updatedEnvironments = await Promise.all(
      environmentsToHydrate.map(async (environment: Environment) => {
        if (!environment.resources || environment.resources.length < 1) {
          const env = await getStackResources(environment);
          return env || environment;
        }
        return environment;
      }),
    );
    setEnvironments(updatedEnvironments);

    // Update selected workspace if it exists in the updated environments
    const selectedWorkspaceUpdate = updatedEnvironments.find((env) => env.workspace);
    if (selectedWorkspaceUpdate) {
      setSelectedWorkspace(selectedWorkspaceUpdate.workspace);
    }
  };

  const createEnvironment = async (environmentData: any) => {
    if (!environmentData.name) {
      notify({ message: 'Please enter a name for your environment.' });
      return;
    }
    if (!environmentData.awsAccountID) {
      notify({ message: 'Please enter your AWS account ID.' });
      return;
    }
    if (!environmentData.awsRegion) {
      notify({ message: 'Please select an AWS region.' });
      return;
    }
    if (!environmentData.awsUserClientId) {
      notify({ message: 'Please enter your AWS client ID.' });
      return;
    }
    if (!environmentData.awsUserSecret) {
      notify({ message: 'Please enter your AWS client secret.' });
      return;
    }
    if (!account.quantityPurchased || account.quantityPurchased <= selectedWorkspace.environments.length + 1) {
      notify({ message: 'You have reached your environment limit.' });
      return;
    }

    const params = { environment: environmentData, workspace: selectedWorkspace, account };
    const request = await post(`environment/create/${user.sub}`, params);

    if (request && response.ok) {
      setAccount(request.account);
      setSelectedWorkspace(request.workspace);
      const newEnvs = filterOutReservedEnvironments(request.environments);

      // Update environments and immediately hydrate to fetch full resources
      setEnvironments(newEnvs);
      await hydrateAllEnvironments(newEnvs);

      notify({ message: '🎉 Environment Created' });
    } else {
      notify({ message: 'Failed to create environment.' });
    }
  };

  const deleteEnvironment = async (environment: Environment) => {
    const params = { environment, workspace: selectedWorkspace, account };
    const deleteRequest = await post(`environment/delete-environment/${user.sub}`, params);

    if (deleteRequest && response.ok) {
      const newEnvs = filterOutReservedEnvironments(environments).filter((env) => env.SK !== environment.SK);
      setEnvironments(newEnvs);
      setAccount(deleteRequest.account);
      setSelectedWorkspace(deleteRequest.workspace);

      // Rehydrate remaining environments to refresh resources
      await hydrateAllEnvironments(newEnvs);

      notify({ message: `␡ Deleted ${environment.name}` });
    } else {
      notify({ message: 'Failed to delete environment.' });
    }
  };
  const deployStack = async (environment: Environment, stack: string) => {
    const params = { environment, workspace: selectedWorkspace, account };
    const deployRequest = await post(`environment/deploy-${stack}/${user.sub}`, params);
    if (deployRequest && response.ok) {
      console.dir(deployRequest);
      await getStackResources(environment);
      setAccount(deployRequest.account);
      setSelectedWorkspace(deployRequest.workspace);
    } else {
      notify({ message: `Failed to deploy ${stack} stack.` });
    }
  };

  const deleteStack = async (environment: Environment, stack: string) => {
    const params = { environment, workspace: selectedWorkspace, account };
    const deleteRequest = await post(`environment/delete-${stack}/${user.sub}`, params);
    if (deleteRequest && response.ok) {
      setAccount(deleteRequest.account);
      setSelectedWorkspace(deleteRequest.workspace);
    } else {
      notify({ message: `Failed to delete ${stack} stack.` });
    }
  };

  const handleEnvironmentNameChange = (event: any, environment: Environment) => {
    const updatedEnv = { ...environment, name: event.target.value };
    setEnvironments((prevEnvs) => prevEnvs.map((env) => (env.SK === environment.SK ? updatedEnv : env)));
  };

  const saveEnvironmentName = async (environment: Environment) => {
    const params = {
      environment,
      workspace: selectedWorkspace,
      account,
    };
    const envUpdateRequest = await post(`account/update-account-environment/${user.sub}`, params);
    if (response.ok && envUpdateRequest) {
      await getStackResources(environment);
      setAccount(envUpdateRequest.account);
      setSelectedWorkspace(envUpdateRequest.workspace);
    } else {
      notify({ message: 'Failed to save environment name.' });
    }
  };

  useEffect(() => {
    getUserCF();
    getRegions();
    hydrateAllEnvironments();
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        environments,
        setEnvironments,
        availableRegions,
        userCF,
        loading,
        account,
        selectedWorkspace,
        createEnvironment,
        deleteEnvironment,
        getUserCF,
        getRegions,
        deployStack,
        deleteStack,
        getStackResources,
        handleEnvironmentNameChange,
        saveEnvironmentName,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error('useEnvironment must be used within an EnvironmentProvider');
  }
  return context;
};
