/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import EnvironmentForm from './EnvironmentForm';
import EnvironmentList from './EnvironmentList';
import { EnvironmentProvider } from '../../context/EnvironmentContext';

export default function Environments(props: any) {
  const { user, account, selectedWorkspace, notify } = props;
  return (
    <EnvironmentProvider user={user} account={account} selectedWorkspace={selectedWorkspace} notify={notify}>
      <div className="p-4">
        <div className="grid grid-cols-7 gap-2">
          <EnvironmentForm />
          <EnvironmentList />
        </div>
      </div>
    </EnvironmentProvider>
  );
}
