/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useEnvironment } from '../../context/EnvironmentContext';
import InputBox from '../Base/Elements/Input/InputBox';
import logo from '../../../images/retrohook.png';

const defaultNewEnvironment = {
  name: '',
  awsAccountID: '',
  awsRegion: '',
  awsUserClientId: '',
  awsUserSecret: '',
  storage: { version: 'latest' },
  network: { version: 'latest' },
  compute: { version: 'latest' },
  stackery: { version: 'latest' },
};

const placeholders = {
  awsRegion: 'Enter AWS Region',
  awsUserClientId: 'Enter AWS User Client ID',
  awsAccountID: 'Enter AWS Account ID',
  awsUserSecret: 'Enter AWS User Client Secret',
  name: 'Enter Environment Name',
};

export default function EnvironmentForm() {
  const { createEnvironment, availableRegions, userCF, loading, notify } = useEnvironment();

  const [environment, setEnvironment] = useState(defaultNewEnvironment);
  const [loadingType, setLoadingType] = useState('');

  const handleInputChange = (event: any, input: string) => {
    setEnvironment({ ...environment, [input]: event.target.value });
  };

  const saveEnvironment = async () => {
    setLoadingType('save');
    await createEnvironment(environment);
    setEnvironment(defaultNewEnvironment);
    setLoadingType('');
  };

  const downloadUserCF = () => {
    // eslint-disable-next-line no-undef
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(userCF)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'retrohook-user.json';
    // eslint-disable-next-line no-undef
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="col-span-2">
      <div className="min-h-full bg-transparent dark:bg-gray-800 p-4 shadow rounded-lg overflow-hidden border-gray-400 hover:border-cyan-300 border-2">
        {loadingType === 'save' && loading ? (
          <div className="flex flex-col justify-center items-center min-h-full">
            <div className="items-center animate-pulse">
              <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
              <h2 className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
                Retrohook
              </h2>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-xs text-gray-400 mt-2 mb-2">
              1.{' '}
              <a onClick={downloadUserCF} className="text-gray-600 py-0.5 border-2 border-pink-400 hover:bg-pink-400 rounded-sm p-1 mx-1 my-1 cursor-pointer">
                Download
              </a>
              the role template.
            </p>
            <p className="text-xs text-gray-400">2. Create a client ID and secret.</p>

            <div className="space-y-2">
              <div className="mt-4">
                <InputBox name="Name" onChange={(event: any) => handleInputChange(event, 'name')} value={environment.name} placeholder={placeholders.name} />
              </div>
              <div className="pb-2 pt-4">
                <InputBox
                  name="AWS Account ID"
                  onChange={(event: any) => handleInputChange(event, 'awsAccountID')}
                  value={environment.awsAccountID}
                  placeholder={placeholders.awsAccountID}
                />
              </div>
              <div className="pb-2 pt-2">
                <InputBox
                  name="AWS Client ID"
                  onChange={(event: any) => handleInputChange(event, 'awsUserClientId')}
                  value={environment.awsUserClientId}
                  placeholder={placeholders.awsUserClientId}
                />
              </div>
              <div className="pb-2 pt-2">
                <InputBox
                  name="AWS Client Secret"
                  onChange={(event: any) => handleInputChange(event, 'awsUserSecret')}
                  value={environment.awsUserSecret}
                  placeholder={placeholders.awsUserSecret}
                />
              </div>
              <div className="pb-2">
                <select
                  className="border-2 appearance-none rounded w-full py-2 px-3 bg-transparent border-cyan-300 text-cyan-300 leading-tight focus:outline-none"
                  value={environment.awsRegion}
                  onChange={(event) => handleInputChange(event, 'awsRegion')}
                >
                  <option value="" disabled>
                    Select AWS Region
                  </option>
                  {availableRegions &&
                    availableRegions.map((region: any, index: number) => (
                      <option key={index} value={region.RegionName}>
                        {region.RegionName}
                      </option>
                    ))}
                </select>
              </div>
              <button
                type="button"
                className="w-full bg-cyan-300 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={saveEnvironment}
              >
                Create Environment
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
